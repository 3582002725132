// Composables
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    component: () => import('@/layouts/default/Default.vue'),
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('@/views/Home.vue'),
      },
      {
        path: '/ProductosDeSeguridad',
        name: 'ProductosDeSeguridad',
        component: () => import('@/views/ProductosDeSeguridad.vue'),
      },
      {
        path: '/productosDeSeguridad/:titulo/:id',
        name: 'productosDeSeguridad/:titulo/:id',
        component: () => import('@/views/productosSeguridad/DetalleProducto.vue'),
      },
      {
        path: '/productos/:idCat',
        name: 'productByCat',
        component: () => import('@/views/ProductosCategoria.vue'),
      },
      {
        path: '/productoDetalle/:idProd',
        name: 'productoDetalle',
        component: () => import('@/views/ProductoDetalle.vue'),
      },
      {
        path: '/servicios',
        name: 'servicios',
        component: () => import('@/views/Servicios.vue'),
      },
      {
        path: '/nosotros',
        name: 'nosotros',
        component: () => import('@/views/Nosotros.vue'),
      },
      {
        path: '/equipoIndustrial',
        name: 'equipoIndustrial',
        component: () => import('@/views/EquipoIndustrial.vue'),
      },

    ],
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

export default router
